import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from "react-toastify";

import * as DISPATCH_STATE from '../savedPlacesChangeState';
import * as LOG_EVENT from "../../../../analytics/index";
import * as CONSTANT_ACTIONS from '../savedPlacesConstant';
import { appFetchFailure } from "../../commonActions";
import { GraphRequest } from '../../../../../axios';
import {
	createSavedPlaceObject,
	createBookmarkedPlaceObject,
	createSavedPlaceObjectForEditing, createDeliveringToObject, editSavedPlaceObject, createLastUsedObject,
} from "../../../../functions/create-place-object-from-google-suggestions";
import { fetchSavedPlaces } from './savedPlaces-list';
import {
	createLastUsedRequestBody,
	createSavedPlaceRequestBody, updateLastUsedRequestBody,
	updateSavedPlaceRequestBody,
} from "./saved-places-gql";
import {
	checkSelectedPlaceExist,
	setDeliveringToPlaceInfo,
	setHomePickedLocation,
	setSelectedAddressForDestination,
} from "./savedPlaces-select";
import {
	getDeliveringToPlaceLocalStorage,
	getHomePickedLocationLocalStorage,
	localStorageIsTwa
} from "../../../../functions/local-storage";
import { closeLocationSearchBottomSheet } from "../../bookingActions/bookingChangeState";
import { changeCurrentLocation, setGooglePlaceObject } from "../../mapActions/mapChangeState";
import { setAddressDetailsForm } from "../savedPlacesActions";
import { mapSetCurrentLocationLocalstorage } from "../../mapActions/actions/map-current-location";
import { createPlaceObjectFromSavedPlaceObject } from "../../../../functions/create-place-object-saved-places";
import { searchSetCurrentReferenceName } from "../../searchActions/searchChangeState";
/**
 * request for saving new saved places
 * @param {*} name 
 */
export const addSavedPlace = (data) => {
    return (dispatch, getState) => {
		const addSavedPlaceRequestBody = {
			query: createSavedPlaceRequestBody,
			variables: { "data": createSavedPlaceObject(data, getState) }
		}
		dispatch(DISPATCH_STATE.addSavedPlaceLoading(true));
		GraphRequest.all(addSavedPlaceRequestBody)
            .then((res) => {
				dispatch(DISPATCH_STATE.addSavedPlaceLoading(false));
				dispatch(setAddressDetailsForm(null));
				if (!_.isNull(res.data.data) && res.data.data.createSavedPlace) {
				// dispatch(createLastUsed(data, res.data.data));
				// if (res.data.type !== CONSTANT_ACTIONS.SAVED_PLACE_CUSTOM_TYPE) {
					if (!_.isNull(getState().savedPlaces.searchPointer)) {
						dispatch(
							setEventForSavedPlaceAdded({
								data,
								productId:
									!_.isNull(getState().booking.product) || !_.isEmpty(getState().booking.product)
										? getState().booking.product.id
										: null,
								isEdited: false,
							}),
						);
						if (getState().savedPlaces.searchPointer === '/account') {
							dispatch(push('/saved-places'));
						} else if (getState().savedPlaces.searchPointer === `/booking/${getState().booking.product.id}`) {
							dispatch(push(`/booking/${getState().booking.product.id}`));
							dispatch(closeLocationSearchBottomSheet());
							let place = createPlaceObjectFromSavedPlaceObject(res.data.data.createSavedPlace);
							dispatch(setSelectedAddressForDestination(getState().search.currentRef, place));
							dispatch(searchSetCurrentReferenceName(""));
						} else {
							if(getState().savedPlaces.searchPointer.includes("review-mymall")) {
								dispatch(setDeliveringToPlaceInfo(res.data.data.createSavedPlace));
								dispatch(setGooglePlaceObject(res.data.data.createSavedPlace?.location))
								dispatch(changeCurrentLocation(res.data.data.createSavedPlace?.location))
								dispatch(closeLocationSearchBottomSheet());
							}
							dispatch(push(getState().savedPlaces.searchPointer));
						}
					}
				// } else {
					// dispatch(push('/saved-places'));
				// }

					dispatch(DISPATCH_STATE.clearSavedPlaceAndType());
					toast(`Added address`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				} else {
					toast(`${res.data.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				}
            })
            .catch((e) => {
                console.error('error', e)
                dispatch(appFetchFailure(e));
            })
    }
}

export const updateSavedPlace = (data, id) => {
	return (dispatch, getState) => {
		const editSavedPlaceRequestBody = {
			query: updateSavedPlaceRequestBody,
			variables: {
				data: createSavedPlaceObjectForEditing(data, getState),
				savedPlaceId: Number(id),
			},
		};
		dispatch(DISPATCH_STATE.addSavedPlaceLoading(true));
		GraphRequest.all(editSavedPlaceRequestBody)
			.then((res) => {
				dispatch(DISPATCH_STATE.addSavedPlaceLoading(false));
				dispatch(DISPATCH_STATE.editLocationSavedPlace(null));
				dispatch(setAddressDetailsForm(null));
				// if (res.data.type !== CONSTANT_ACTIONS.SAVED_PLACE_CUSTOM_TYPE) {
					if (!_.isNull(getState().savedPlaces.searchPointer)) {
						if (!_.isNull(getDeliveringToPlaceLocalStorage())) {
							if (getDeliveringToPlaceLocalStorage().id === Number(id)) {
								dispatch(setGooglePlaceObject(editSavedPlaceObject(data, getState).location))
								dispatch(changeCurrentLocation(editSavedPlaceObject(data, getState).location))
								dispatch(setDeliveringToPlaceInfo(editSavedPlaceObject(data, getState)));
								dispatch(mapSetCurrentLocationLocalstorage(editSavedPlaceObject(data, getState).location));
							}
						}
						if (!_.isNull(getHomePickedLocationLocalStorage())) {
							if (getHomePickedLocationLocalStorage().id === Number(id)) {
								dispatch(setHomePickedLocation(editSavedPlaceObject(data, getState)));
							}
						}
						if (getState().savedPlaces.searchPointer === "/account") {
							dispatch(push("/saved-places"));
						} else {
							dispatch(push(getState().savedPlaces.searchPointer));

						}
					}
				// } else {
				// 	dispatch(push("/saved-places"));
				// }
				if (!_.isNull(res.data.data) && res.data.data.updateSavedPlace) {
					dispatch(DISPATCH_STATE.clearSavedPlaceAndType());
					dispatch(
						setEventForSavedPlaceAdded({
							data,
							productId:
								!_.isNull(getState().booking.product) || !_.isEmpty(getState().booking.product)
									? getState().booking.product.id
									: null,
							isEdited: true,
						}),
					);
					toast(`Address Updated`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				} else {
					toast(`${res.data.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				}
			})
			.catch((e) => {
				console.error("error", e);
				dispatch(appFetchFailure(e));
			});
	}
}

export const addBookmarkedPlace = (savedPlace) => {
	return (dispatch, getState) => {
		const addBookmarkPlaceRequestBody = {
			query: createSavedPlaceRequestBody,
			variables: { "data": createBookmarkedPlaceObject(savedPlace) }
		}
		dispatch(requestAddBookmarkedPlace(addBookmarkPlaceRequestBody));
	};
};

export const undoBookmarkedPlace = (savedPlace) => {
	return (dispatch, getState) => {
		let addSavedPlaceRequestBody;
		if (savedPlace.location.id || _.isNull(savedPlace.location.id)) delete savedPlace.location.id;
		if (savedPlace.id || _.isNull(savedPlace.id)) delete savedPlace.id;
		addSavedPlaceRequestBody = {
			query: createSavedPlaceRequestBody,
			variables: { "data": savedPlace }
		}
		dispatch(requestAddBookmarkedPlace(addSavedPlaceRequestBody))
	};
};

export const requestAddBookmarkedPlace = (requestBody) => {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.fetchSavedPlacesLoading(true));
		GraphRequest.all(requestBody)
			.then((res) => {
				dispatch(DISPATCH_STATE.fetchSavedPlacesLoading(false));
				if (!_.isNull(res.data.data) && res.data.data.createSavedPlace) {
					dispatch(DISPATCH_STATE.clearSavedPlaceAndType());
					dispatch(fetchSavedPlaces());
					toast(`Added address`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				} else {
					dispatch(appFetchFailure(res.data.errors[0].message));
					toast(`${res.data.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				}
			}).catch((e) => {
				dispatch(appFetchFailure(e));
			});
	}
}

export const setEventForOpenMapOnSavedPlaces = (info) => {
	return (dispatch, getState) => {
		switch (getState().savedPlaces.searchPointer) {
			case "/home":
				if (getState().savedPlaces.pathname === "search") {
					LOG_EVENT.logEvent(LOG_EVENT.ADD_SAVED_PLACED_OPENED, { from: "search", os: localStorageIsTwa() ? "twa" : "web", isEdited: info.isEdited });
				} else {
					LOG_EVENT.logEvent(LOG_EVENT.ADD_SAVED_PLACED_OPENED, { os: localStorageIsTwa() ? "twa" : "web", from: "home", isEdited: info.isEdited });
				}
				break;
			case "/account":
				LOG_EVENT.logEvent(LOG_EVENT.ADD_SAVED_PLACED_OPENED, { os: localStorageIsTwa() ? "twa" : "web", from: "account", isEdited: info.isEdited });
				break;
			case `/booking/${info.productId}`:
				LOG_EVENT.logEvent(LOG_EVENT.ADD_SAVED_PLACED_OPENED, { os: localStorageIsTwa() ? "twa" : "web", from: "addStop", isEdited: info.isEdited });
				break;
			case `/booking/${info.productId}/review-mymall`:
				LOG_EVENT.logEvent(LOG_EVENT.ADD_SAVED_PLACED_OPENED, { os: localStorageIsTwa() ? "twa" : "web", from: "review", isEdited: info.isEdited });
				break;
			default:
				break;
		}
	};
}

export const setEventForLocationConfirmedSavedPlaces = (info) => {
	return (dispatch, getState) => {
		switch (getState().savedPlaces.searchPointer) {
			case "/home":
				if (getState().savedPlaces.pathname === "search") {
					LOG_EVENT.logEvent(LOG_EVENT.LOCATION_CONFIRMED, {
						fromSearch: info.isSearching,
						from: "search",
						os: localStorageIsTwa() ? "twa" : "web",
						isEdited: info.isEdited,
					});
				} else {
					LOG_EVENT.logEvent(LOG_EVENT.LOCATION_CONFIRMED, {
						fromSearch: info.isSearching,
						os: localStorageIsTwa() ? "twa" : "web",
						from: "home",
						isEdited: info.isEdited,
					});
				}
				break;
			case "/account":
				LOG_EVENT.logEvent(LOG_EVENT.LOCATION_CONFIRMED, {
					fromSearch: info.isSearching,
					os: localStorageIsTwa() ? "twa" : "web",
					from: "account",
					isEdited: info.isEdited,
				});
				break;
			case `/booking/${info.productId}`:
				LOG_EVENT.logEvent(LOG_EVENT.LOCATION_CONFIRMED, {
					fromSearch: info.isSearching,
					os: localStorageIsTwa() ? "twa" : "web",
					from: "addStop",
					isEdited: info.isEdited,
				});
				break;
			case `/booking/${info.productId}/review-mymall`:
				LOG_EVENT.logEvent(LOG_EVENT.LOCATION_CONFIRMED, {
					fromSearch: info.isSearching,
					os: localStorageIsTwa() ? "twa" : "web",
					from: "review",
					isEdited: info.isEdited,
				});
				break;
			default:
				break;
		}
	};
};

export const setEventForLocationFromMapConfirmed = (info) => {
	return (dispatch, getState) => {
		switch (info.pathname) {
			case "/home":
				if (getState().savedPlaces.pathname === "search") {
					LOG_EVENT.logEvent(LOG_EVENT.LOCATION_FROM_MAP_CONFIRMED, { 
						from: "search", 
						os: localStorageIsTwa() ? "twa" : "web", 
						fromSearch: info.fromSearch,
						isGuest: getState().common.auth && getState().common.authCheck ? false : true
					});
				} else {
					LOG_EVENT.logEvent(LOG_EVENT.LOCATION_FROM_MAP_CONFIRMED, {
						from: "home",
						os: localStorageIsTwa() ? "twa" : "web",
						fromSearch: info.fromSearch,
						isGuest: getState().common.auth && getState().common.authCheck ? false : true,
					});
				}
				break;
			case `/booking/${info.productId}`:
				LOG_EVENT.logEvent(LOG_EVENT.LOCATION_FROM_MAP_CONFIRMED, {
					from: "addStop",
					os: localStorageIsTwa() ? "twa" : "web",
					fromSearch: info.fromSearch,
					isGuest: getState().common.auth && getState().common.authCheck ? false : true,
				});
				break;
			default:
				break;
		}
	};
};

export const setEventForSavedPlaceAdded = (info) => {
	return (dispatch, getState) => {
		switch (getState().savedPlaces.searchPointer) {
			case "/home":
				if (getState().savedPlaces.pathname === "search") {
					LOG_EVENT.logEvent(LOG_EVENT.SAVED_PLACE_ADDED, {
						fromSearch: info.isSearching,
						title: info.data.name !== "Home" || info.data.name !== "Work" ? "Custom" : info.data.name,
						hasNote: info.data.note?.length ? true : false,
						dontCall: info.data.phoneCall,
						from: "search",
						os: localStorageIsTwa() ? "twa" : "web",
						isEdited: info.isEdited,
					});
				} else {
					LOG_EVENT.logEvent(LOG_EVENT.SAVED_PLACE_ADDED, {
						fromSearch: info.isSearching,
						title: info.data.name !== "Home" || info.data.name !== "Work" ? "Custom" : info.data.name,
						hasNote: info.data.note?.length ? true : false,
						dontCall: info.data.phoneCall,
						from: "home",
						os: localStorageIsTwa() ? "twa" : "web",
						isEdited: info.isEdited,
					});
				}
				break;
			case "/account":
				LOG_EVENT.logEvent(LOG_EVENT.SAVED_PLACE_ADDED, {
					fromSearch: info.isSearching,
					title: info.data.name !== "Home" || info.data.name !== "Work" ? "Custom" : info.data.name,
					hasNote: info.data.note?.length ? true : false,
					dontCall: info.data.phoneCall,
					from: "account",
					os: localStorageIsTwa() ? "twa" : "web",
					isEdited: info.isEdited,
				});
				break;
			case `/booking/${info.productId}`:
				LOG_EVENT.logEvent(LOG_EVENT.SAVED_PLACE_ADDED, {
					fromSearch: info.isSearching,
					title: info.data.name !== "Home" || info.data.name !== "Work" ? "Custom" : info.data.name,
					hasNote: info.data.note?.length ? true : false,
					dontCall: info.data.phoneCall,
					from: "addStop",
					os: localStorageIsTwa() ? "twa" : "web",
					isEdited: info.isEdited,
				});
				break;
			case `/booking/${info.productId}/review-mymall`:
				LOG_EVENT.logEvent(LOG_EVENT.SAVED_PLACE_ADDED, {
					fromSearch: info.isSearching,
					title: info.data.name !== "Home" || info.data.name !== "Work" ? "Custom" : info.data.name,
					hasNote: info.data.note?.length ? true : false,
					dontCall: info.data.phoneCall,
					from: "review",
					os: localStorageIsTwa() ? "twa" : "web",
					isEdited: info.isEdited,
				});
				break;
			default:
				break;
		}
	};
};