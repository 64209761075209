export const fetchAllJobs = `
query Requests($offset: Int, $limit: Int) {
  requests(offset:$offset, limit:$limit, requestType: All) {
    totalItems 
    items {
        id
        dateTime
        dueDate
        jobCost {
            value
            currency
            serviceFee
            discount
            discountedServiceFee
            extendFee
            clientTransferMykoin
        }
        product {
            commercialName
            icon
        }
        jobLocations {
            name
            address
        }
        jobTime {
            plate {
                value
                unit
                displayUnit
                displayValue
            }
            cube {
                value
                unit
                displayUnit
                displayValue
            }
            total {
                value
                unit
                displayUnit
                displayValue
            }
            cubeNumbers
        }
        state
    }
  }
}`;

export const fetchJobById = `query GetRequest($requestId: Int!) {
  request(requestId: $requestId) {
    id
    receivedCashAmount
    dueDate
    state
    bookingId
    dateTime
    note
    partnerLifeCycle {
      location {
        lat
        lng
      }
    }
    voucher {
      code
      totalDiscount
      type
      percentOff
      amountOff
    }
    partner {
        givenName
        lastName
        gender
        title
        coPartner {
          displayValue
          name
        }
        photo
    }
    startDateTime
    completeDateTime
    client {
        givenName
        lastName
    }
    payments {
      unit
      isPaid
      card {
        type
        icon
        name
        cardNumber
      }
      cardUsage
      mykoinUsage
    }
    product {
      commercialName
      id
      mode
    }
    jobLocations {
      address
      lat
      lng
    }
    clientRate {
      rate
      comment
      compliments {
        id
        name
        feedbackType
      }
    }
    jobTime {
        plate {
            value
            unit
            displayUnit
            displayValue
        }
        cube {
            value
            unit
            displayUnit
            displayValue
        }
        total {
            value
            unit
            displayUnit
            displayValue
        }
        cubeNumbers
    }
    jobCost {
      value
      currency
      serviceFee
      discount
      discountedServiceFee
      extendFee
      clientTransferMykoin
      transferFee
    }
  }
}`;

export const fetchActiveJobRequestBody = `query GetRequest($requestId: Int!) {
  request(requestId: $requestId) {
    id
    state
    startDateTime
    dueDate
    note
    voucher {
      code
      totalDiscount
      type
      percentOff
      amountOff
    }
    bookingId
    partner {
        givenName
        lastName
        gender
        title
        coPartner {
          displayValue
          name
        }
        photo
        mobileNumber {
          fullNumber
        }
    }
    payments {
      unit
      isPaid
      card {
        type
        icon
        name
        cardNumber
      }
      cardUsage
      mykoinUsage
    }
    product {
      mode
      commercialName
      id
    }
    jobLocations {
      address
      lat
      lng
    }
    jobCost {
      value
      currency
      serviceFee
      discount
      discountedServiceFee
      extendFee
      clientTransferMykoin
      transferFee
    }
    jobTime {
        plate {
            value
            unit
            displayUnit
            displayValue
        }
        cube {
            value
            unit
            displayUnit
            displayValue
        }
        total {
            value
            unit
            displayUnit
            displayValue
        }
        cubeNumbers
    }
  }
}`;

export const jobStateCheckRequestBody = `query GetRequest($requestId: Int!) {
  request(requestId: $requestId) {
    id
    state
  }
}`;

export const unratedJobAndJobStateCheckerRequestBody = `query GetClientUnratedJobs($requestId: Int!, $offset: Int, $limit: Int) {
  request(requestId: $requestId) {
      id
      state
  }
  unratedJobs: requests(requestType: Unrated, offset: $offset, limit: $limit) {
    totalItems
    items {
      id
      bookingId
      dateTime
      product {
        commercialName
        id
      }
      jobLocations {
        address
        name
      }
      partner {
        givenName
        lastName
        photo
        coPartner {
          displayValue
          name
        }
      }
    }
  }
}`;

export const fetchComplimentsRequestBody = `query JobRatingCompliments($requestId: Int!) {
  request(requestId: $requestId) {
    product {
      compliments {
        rate
        compliments {
          id
          name
        }
      }
    }
  }
}`;

export const jobRateRequestBody = `mutation JobRatePartner($rating: ClientJobPartnerRatingRateInput!,$jobId: Int!) {
  jobRatePartner(rating: $rating, jobId: $jobId) {
    id
    state
  }
}`;

export const cancelJobRequestBody = `mutation ClientCancelJob($cancelReason: ClientJobCancelReasonInput!, $jobId: Int!) {
  cancelJob(cancelReason: $cancelReason, jobId: $jobId) {
    id
    state
  }
}`;

export const cancelJobReasonsBody = `query ClientRequestCancelReasons($requestId: Int!) {
  requestCancelReasons(requestId: $requestId) {
    items
    totalItems
  }
}`;

export const fetchPartnerOfJob = `query GetRequest($requestId: Int!) {
  request(requestId: $requestId) {
    id
    state
    bookingId
    partner {
        givenName
        lastName
        title
        gender
        coPartner {
          displayValue
          name
        }
        photo
    }
    product {
      commercialName
      id
      mode
    }
  }
}`;

export const fetchJobsQuery = `
query UpcomingJobs {
  queued: requests(requestType: OPEN_QUEUED, limit: 50) {
    totalItems
    items {
      ...item
    }
  }
  
  matching:requests(requestType: OPEN_MATCHING, limit: 50) {
    totalItems
    items {
      ...item
    }
  }
  
  scheduled: requests(requestType: OPEN_SCHEDULED, limit: 50) {
    totalItems
    items {
      ...item
    }
  }
}

fragment item on ClientRequestType {
  id
      bookingId
      dateTime
      dueDate
      jobCost {
        displayValue
        value
        currency
        serviceFee
        discount
        discountedServiceFee
        extendFee
        clientTransferMykoin
      }
      voucher {
        code
        amountOff
        percentOff
        totalDiscount
        type
      }
      partner {
        id
        givenName
        lastName
        gender
        coPartner {
          name
          displayValue
        }
        photo
        mobileNumber {
          countryCode {
            code
          }
          number
          fullNumber
        }
      }
      product {
        id
        icon
        commercialName
        mode
      }
      state
      jobLocations {
        name
        address
        lat
        lng
      }
      jobTime {
        total {
          unit
          value
          displayUnit
          displayValue
        }
        cubeNumbers
      }
}`;