import _ from 'lodash';
import { SAVED_PLACE_CUSTOM_TYPE } from '../redux/actions/savedPlacesActions/savedPlacesConstant';

/**
 * create object place from google suggestions for pass throw google places and user location
 * @param {*} getState 
 */
export function createPlaceObjectFromGoogleSuggestions(getState, index) {
    return {
        title: getState().map.googleSearchPlaces[ index ][ 'name' ],
        lat: _.isFunction(getState().map.googleSearchPlaces[ index ].geometry.location.lat) ? getState().map.googleSearchPlaces[ index ].geometry.location.lat() : getState().map.googleSearchPlaces[ index ].geometry.location.lat,
        lng: _.isFunction(getState().map.googleSearchPlaces[ index ].geometry.location.lng) ? getState().map.googleSearchPlaces[ index ].geometry.location.lng() : getState().map.googleSearchPlaces[ index ].geometry.location.lng,
        address: getState().map.googleSearchPlaces[ index ][ 'formatted_address' ],
    }
}
/**
 * create object place from google suggestions for pass throw google places and user location
 * @param {*} getState 
 */
export function createPlaceObjectFromGoogleSuggestionsVicinity(getState, index) {
    let place = {
        title: getState().map.googleSearchPlaces[ index ][ 'name' ],
        lat: _.isFunction(getState().map.googleSearchPlaces[ index ].geometry.location.lat) ? getState().map.googleSearchPlaces[ index ].geometry.location.lat() : getState().map.googleSearchPlaces[ index ].geometry.location.lat,
        lng: _.isFunction(getState().map.googleSearchPlaces[ index ].geometry.location.lng) ? getState().map.googleSearchPlaces[ index ].geometry.location.lng() : getState().map.googleSearchPlaces[ index ].geometry.location.lng,
        address: getState().map.googleSearchPlaces[ index ][ 'vicinity' ] || getState().map.googleSearchPlaces[ index ][ 'formatted_address' ],
    }
    if (place[ 'title' ] !== place[ 'address' ]) {
        place[ 'address' ] = place[ 'title' ] + ', ' + place[ 'address' ];
    }

    return place;
}
/**
 * create object place from google suggestions for pass throw google places and user location
 * @param {*} getState 
 */
export function createPlaceGoogleSuggestionsFromRawPlace(rawPlace, name) {
    
    if(!_.isEmpty(rawPlace)) {
        let place = {
            // title: rawPlace[ 'name' ],
            title: name,
            lat: _.isFunction(rawPlace.geometry.location.lat) ? rawPlace.geometry.location.lat() : rawPlace.geometry.location.lat,
            lng: _.isFunction(rawPlace.geometry.location.lng) ? rawPlace.geometry.location.lng() : rawPlace.geometry.location.lng,
            address: rawPlace[ 'vicinity' ] || rawPlace[ 'formatted_address' ],
        }
        if (place[ 'title' ] !== place[ 'address' ]) {
            place[ 'address' ] = place[ 'title' ] + ', ' + place[ 'address' ];
        }
    
        return place;
    }
}

/**
 * create object place from google suggestions for pass throw google places and user location
 * @param {*} getState
 */
export function createPlaceGoogleSuggestionsFromGeoCode(results) {
    if(!_.isEmpty(results[0])) {
        let place = {
            title: results[0].address_components.filter(ac=>~ac.types.indexOf('locality'))[0].long_name,
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
            address: results[0].formatted_address, //formatAddress(results[0]),
        }

        return place;
    }
}

export function formatAddress(address) {
	if(address.address_components[0].types[0] === "plus_code") {
		return  address.formatted_address.replace(`${address.address_components[0].long_name},`, "");
	} else {
		return address.formatted_address
	}
}
/**
 * 
 * @param {*} getState 
 * @param {*} place 
 */
export function addUserLocationToPlace(getState, place) {
    return {
        ...getState().map.location,
        title: place.title,
        lat: place.lat,
        lng: place.lng,
        address: place.address
    }
}
/**
 * 
 * @param {*} results 
 * @param {*} location 
 * @param {*} index 
 */
export function createPlaceObjectFromGoogleNearsearchSuggestions(results, location, index) {
    return {
        title: results[ index ][ 'name' ],
        lat: location.lat,
        lng: location.lng,
        address: results[ index ][ 'vicinity' ]
    }
}
/**
 * 
 * @param {*} results 
 * @param {*} location 
 * @param {*} index 
 */
export function createPlaceObjectFromGoogleGetExactAddress(results, location, index) {
    let formatted_address = '';
    if (_.has(results, index)) {
        formatted_address = filterAddressComponent(results[ index ][ 'address_components' ]);
    }
    
    return {
        title: formatted_address,
        lat: location.lat,
        lng: location.lng,
        address: formatted_address
    }
}
export function createPlaceObjectFromGoogleGetExactAddressFromRoute(results, location, index) {
	return {
		title: results[index]?.address_components?.filter(ac=>~ac.types.indexOf('locality'))[0]?.long_name || "",
		lat: location.lat,
		lng: location.lng,
		address: results[index]?.formatted_address || "" // ? formatAddress(results[index]) : "",
	};
}
/**
 * filter address components
 * @param {*} addressComponents 
 */
export function filterAddressComponent(addressComponents) {
    let filterAddress = _.filter(addressComponents, (address) => {
        return !address.types.includes("country")
            && !address.types.includes("postal_code")
            && !address.types.includes("administrative_area_level_1")
            && !address.types.includes("administrative_area_level_2")
            && !address.types.includes("administrative_area_level_3")
            && !address.types.includes("administrative_area_level_4")
            && !address.types.includes("administrative_area_level_5")
            && !address.types.includes("plus_code")
    });
	return concatFilterAddressComponent(filterAddress);
}
/**
 * concat filter address component
 * @param {*} filterAddress 
 */
export function concatFilterAddressComponent(filterAddress) {
    let formatted_address = '';
    _.forEach(filterAddress, function (value, key) {
        formatted_address += value.short_name;
        if (filterAddress.length - 1 !== key) {
            formatted_address += ', ';
        }
    });
    return formatted_address;
}
/**
 * 
 * @param {*} getState 
 * @param {*} result
 * @param {*} index 
 */
export function createPlaceObjectFromGoogleNearsearchWithCurrentLocation(getState, result) {

    return {
        ...getState().map.location,
        title: result[ 'name' ],
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
        address: result[ 'vicinity' ]
    }
}
/**
 * 
 * @param {*} results 
 * @param {*} index 
 */
export function createPlaceObjectForCurrentLocation(result) {
    return {
        title: result[ 'name' ],
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
        address: result[ 'vicinity' ]
    }
}

export const createSavedPlaceObject = (data, getState) => {
    return {
		title: data.name,
		type: getState().savedPlaces.savedType,
		phoneCall: data.phoneCall ? "DO_NOT_CALL_ME" : "CALL_ME",
		note: data.note,
		location: {
			name: data.name,
			address: data.address,
			lat: getState().savedPlaces.savedPlace.lat || getState().savedPlaces.savedPlace.location.lat,
			lng: getState().savedPlaces.savedPlace.lng || getState().savedPlaces.savedPlace.location.lng,
			data: data.details,
		},
	};
}

export const editSavedPlaceObject = (data, getState) => {
	console.log(data, ';::::::::::::::::::::::');
    return {
		id: getState().savedPlaces.savedPlace.id,
		title: data.name,
		type: getState().savedPlaces.savedType,
		phoneCall: data.phoneCall ? "DO_NOT_CALL_ME" : "CALL_ME",
		note: data.note,
		location: {
			name: data.name,
			address: data.address,
			lat: getState().savedPlaces.savedPlace.location.lat,
			lng: getState().savedPlaces.savedPlace.location.lng,
			data: data.details,
			title: data.name,
		},
	};
}

export const createLastUsedObject = (savedPlace) => {
	return {
		referenceSavedPlaceId: savedPlace.id,
		title: savedPlace.title || savedPlace.location.address,
		type: "LastUsed",
		phoneCall: savedPlace.phoneCall,
		note: savedPlace.note,
		location: {
			address: savedPlace.location.address,
			lat: savedPlace.location.lat,
			lng: savedPlace.location.lng,
			data: savedPlace.location.data,
		},
	};
}

export const createSavedPlaceObjectForEditing = (data, getState) => {
	return {
		title: data.name,
		type: getState().savedPlaces.savedType,
		phoneCall: data.phoneCall ? "DO_NOT_CALL_ME" : "CALL_ME",
		note: data.note,
		location: {
			address: data.address,
			lat: getState().savedPlaces.savedPlace.location.lat,
			lng: getState().savedPlaces.savedPlace.location.lng,
			data: data.details,
		},
	};
};

export const createUpdatedSavedPlaceObject = (updatedPlace, prevPlace) => {
	return {
		title: prevPlace.title,
		type: prevPlace.type,
		id: prevPlace.id,
		note: prevPlace.note,
		phoneCall: prevPlace.phoneCall,
		location: {
			address: _.isUndefined(updatedPlace.location) ? updatedPlace.address : updatedPlace.location.address,
			lat: _.isUndefined(updatedPlace.location) ? updatedPlace.lat : updatedPlace.location.lat,
			lng: _.isUndefined(updatedPlace.location) ? updatedPlace.lng : updatedPlace.location.lng,
			data: prevPlace.location.data,
			name: prevPlace.title,
		},
	};
}

export const createBookmarkedPlaceObject = (getState) => {
    return {
        title: getState.title ? getState.title : getState().savedPlaces.savedPlace.title,
        type: SAVED_PLACE_CUSTOM_TYPE,
        isBookmarked: true,
        location: {
            name: getState.title ? getState.title : getState().savedPlaces.savedPlace.title,
            address: getState.address ? getState.address : getState().savedPlaces.savedPlace.address,
            lat: getState.lat ? getState.lat : getState().savedPlaces.savedPlace.lat,
            lng: getState.lng ? getState.lng : getState().savedPlaces.savedPlace.lng
        }
    }
}

export const separateCityName = (address) => {
	const separatedAddress = address?.split(",");
	return separatedAddress?.[separatedAddress.length - 1]
}