import _ from 'lodash';
import * as moment from 'moment-timezone';

export const MESSAGE_TYPE_SYSTEM = 'system';
export const MESSAGE_TYPE_PHOTO = 'photo';
export const MESSAGE_TYPE_FILE = 'audio';
export const MESSAGE_TYPE_MEDIA = 'media';
export const MESSAGE_TYPE_TEXT = 'text';
export const MESSAGE_POSITION_RIGHT = 'right';
export const MESSAGE_POSITION_LEFT = 'left';
export const MESSAGE_CHAT_MESSAGE_HISTORY = 'chatMessagesHistory';
export const MESSAGE_TIMEZONE_FORMAT = "MMM DD, h:mm A";
export const MESSAGE_TIMEZONE_SYSTEM_FORMAT = 'MMM DD, h:mm A';
// export const MESSAGE_TIMEZONE_SYSTEM_FORMAT = 'MMMM DD, YYYY, h:mma';
export const MESSAGE_TIMEZONE_AREA = 'Asia/Manila';
export const MESSAGE_STATUS_SENT = 'sent';
export const MESSAGE_STATUS_RECEIVED = 'received';
export const MESSAGE_STATUS_WAITING = 'waiting';

// new service
export const MESSAGE_FROM_CLIENT = "CLIENT";
export const MESSAGE_FROM_PARTNER = "PARTNER";

export const MESSAGE_TYPE_IMAGE = 'IMAGE';
export const MESSAGE_TYPE_AUDIO = 'AUDIO';

export function chatMessagesHistory(job, newMessage) {
    let messages = [];
    let newJob = job;

    if (_.has(newJob, MESSAGE_CHAT_MESSAGE_HISTORY)) {
        messages = [ ...newJob[ MESSAGE_CHAT_MESSAGE_HISTORY ], ...newMessage ]
    } else {
        messages = [ ...newMessage ]
    }
    newJob[ MESSAGE_CHAT_MESSAGE_HISTORY ] = messages;

    return newJob;
}

/**
 * Create object of message from job chat history
 * @param {*} job 
 */
export function messageObjectCreator(job, client) {
    let messages = [];

    if (_.has(job, MESSAGE_CHAT_MESSAGE_HISTORY)) {
        messages = job.chatMessagesHistory;
    }
    messages = messages.map((message, index) => {
        if (message.type === MESSAGE_TYPE_MEDIA) {
            if (isImage(message.media.content_type)) {
                message.type = MESSAGE_TYPE_IMAGE.toLocaleLowerCase();
            } else {
                message.type = MESSAGE_TYPE_FILE;
            }
            message.data = { 
                uri: new Promise((resolve) => { 
                    resolve(message.media.url); 
                }),
                format: message.media.content_type 
            };
        } else {
            message.type = MESSAGE_TYPE_TEXT;
        }
        message.position = specifyPostionOfMessage(message, 'from', client);
        message.text = message.body;
        message.status = MESSAGE_STATUS_RECEIVED;
        message.dateString = messageDateTimeByAuthor(message.dateCreated, message[ 'from' ]) //moment(message.dateCreated).tz(MESSAGE_TIMEZONE_AREA).format(MESSAGE_TIMEZONE_FORMAT);
        return message;
    });
    const totalItems = messages.length;

    return [{
        'items': messages, 'totalItems': totalItems
    }];
}
/**
 * Check content type of media from message
 * @param {*} contentType 
 */
function isImage(contentType) {
    const acceptedImageTypes = [ 'gif', 'jpeg', 'png', 'jpg', 'image/jpeg', 'image/png', 'image/jpg' ];
    return acceptedImageTypes.includes(contentType);
}

function messageDateTimeByAuthor(time, author) {
    if (author === 'system') {
        return moment(time).format(MESSAGE_TIMEZONE_SYSTEM_FORMAT);
    } else {
        return moment(time).format(MESSAGE_TIMEZONE_FORMAT);
    }
}

/**
 * specify postion of message left|right from message to & from
 * @param {*} message 
 */
function specifyPostionOfMessage(message, from, client) {
    if (message[ from ] === MESSAGE_TYPE_SYSTEM) {
        return MESSAGE_POSITION_LEFT;
    } else if (_.has(client, 'id') && parseInt(client.id) === parseInt(message[ from ])) {
        return MESSAGE_POSITION_RIGHT;
    } else {
        return MESSAGE_POSITION_LEFT;
    }
}

export function messageObjectCreatorFromChatService(listMessages, client = null) {
    let messages = [];
    messages = listMessages.items.map((message, index) => {
        let messageObj = {};
        if (message.type === "IMAGE") {
            messageObj.type = MESSAGE_TYPE_IMAGE.toLowerCase();
            messageObj.data = {
                uri: new Promise((resolve) => {
                    resolve(message.media);
                }),
                format: message.mimeType
            };
        } else if (message.type === "AUDIO") {
            messageObj.type = MESSAGE_TYPE_AUDIO.toLowerCase();
            messageObj.data = {
                uri: new Promise((resolve) => {
                    resolve(message.media);
                }),
                format: message.mimeType
            };
        } else {
            messageObj.type = message.type.toLowerCase();
        }
        messageObj.tokenPayload = message.tokenPayload;
        messageObj.index = message.index;
        messageObj.authorType = message.authorType;
        messageObj.consumedBy = message.consumedBy;
        messageObj.status = message.consumedBy.length > 1 ? MESSAGE_STATUS_RECEIVED : MESSAGE_STATUS_SENT;
        messageObj.text = message.text;
        messageObj.dateString = messageDateTimeByAuthor(message.createdAt, message.authorType.toLowerCase());
        messageObj.extraInfo = message.extraInfo;
        messageObj.referenceId = message.referenceId;
        messageObj.state = message.state;
        // messageObj.author = message.authorType === "SYSTEM" ? null : message.authorId.toString();
        return messageObj;
    });
    const totalItems = messages.length;

    return [ {
        'items': messages, 'totalItems': totalItems
    } ];
}
/**
 * create object for message text
 * @param {*} message 
 */
export function messageObjectForMeWithNewService(message, file = null) {
    if (!_.isNull(file)) {
        return fileMessageMeNewService(message, file);
    } else {
        return textMessageFromMeNewService(message);
    }
}
/**
 * Message text from me
 * @param {*} message 
 */
function textMessageFromMeNewService(message) {
    return {
        authorType: MESSAGE_FROM_CLIENT,
        text: message,
        type: MESSAGE_TYPE_TEXT,
        author: message.authorId ? message.authorId : '',
        dateString: moment(new Date()).format(MESSAGE_TIMEZONE_FORMAT),
        status: MESSAGE_STATUS_WAITING,
    }
}
/**
 * Handle file message of me for show as message
 * @param {*} message 
 * @param {*} file 
 */
function fileMessageMeNewService(message, file = null) {
    if (file.reader && file.reader instanceof FileReader) {
        return {
            authorType: MESSAGE_FROM_CLIENT,
            data: {
                uri: new Promise((resolve) => {
                    resolve(file.reader.result);
                }),
                format: file.type
            },
            type: MESSAGE_TYPE_IMAGE.toLowerCase(),
            dateString: moment(new Date()).format(MESSAGE_TIMEZONE_FORMAT)
        }
    } else {
        return {
            authorType: MESSAGE_FROM_CLIENT,
            data: {
                uri: new Promise((resolve) => {
                    resolve(file.blobURL);
                }),
                format: file.format
            },
            type: MESSAGE_TYPE_FILE,
            dateString: moment(new Date()).format(MESSAGE_TIMEZONE_FORMAT)
        }
    }
}
/**
 * Highlight phone numbers in text messages
 * @param {*} alltexts 
 * @param {*} phonenumbers 
 */
export function highlight_phones(html, phones) {
	let endedAt = 0;
	return (
		phones.reduce((result, { startsAt, endsAt }) => {
			const previousEndsAt = endedAt;
			endedAt = endsAt;
			return (
				result +
				html.slice(previousEndsAt, startsAt) +
				`<span>` +
				html.slice(startsAt, endsAt) +
				"</span>"
			);
		}, "") + html.slice(endedAt)
	);
}