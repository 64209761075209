import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import _ from "lodash";

import SavedPlacesFavorite from "../SavedPlacesFavorites/SavedPlacesFavorites";
import SavedPlacesOthers from "../SavedPlacesOthers/SavedPlacesOthers";
import SingleListSkeleton from "../../ui-elements/Skeleton/SingleListSkeleton";

import {
	fetchSavedPlaces,
	savedPlacesSetType,
	SAVED_PLACE_CUSTOM_TYPE,
	checkSelectedPlaceExist,
	savedPlaceUnSetSearchLocationPointer,
	setDeliveringToPlaceInfo,
	editLocationSavedPlace,
	setEventForOpenMapOnSavedPlaces, SAVED_PLACE_HOME_TYPE,
} from "../../../utils/redux/actions/savedPlacesActions";
import { closeLocationSearchBottomSheet } from "../../../utils/redux/actions/bookingActions/bookingChangeState";
import TitleBar from "../../../organisms/title-bar";
import backButton from "../../../v5-icons/close-thin.svg";
import mapIcon from "../../../v5-icons/map.svg";
import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import $ from "jquery";
import { setNavigationBlackBox } from "../../../utils/redux/actions/commonActions/commonChangeState";

const classNames = require('classnames');

class SavedPlacesBottomSheet extends Component {
	constructor(props) {
		super(props);
		this.state = {
			innerWidth: null,
			isMenuOpen: false,
			hasShadow: false
		};
	}

	componentDidMount() {
		this.setState(() => ({
			innerWidth: window.innerWidth,
		}));
		if (this.props.common.authCheck) {
			this.props.dispatch(fetchSavedPlaces());
			this.props.dispatch(editLocationSavedPlace(null));
			// this.props.dispatch(savedPlaceUnSetSearchLocationPointer());
			// this.props.dispatch(fetchSearchPlacesGoogleInit());
		}

		if (typeof window !== "undefined") {
			const contentTop = $(".saved-places-container").offset().top;
			const contentBottom = contentTop + $(".saved-places-container").outerHeight() - 100;
			const addAddressBtnTop = $(".add-new-address").offset().top;
			if (contentBottom >= addAddressBtnTop) {
				this.setState({hasShadow: true});
			}
		}

		$(".choose-location-bottom-sheet").css("height", "100%");
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if ((this.props.savedPlaces.all?.extra?.length !== prevProps.savedPlaces.all?.extra?.length)
			|| (this.props.savedPlaces.all?.specific?.length !== prevProps.savedPlaces.all?.specific?.length)
		) {
			const contentTop = $(".saved-places-container").offset().top;
			const contentBottom = contentTop + $(".saved-places-container").outerHeight() - 100;
			const addAddressBtnTop = $(".add-new-address").offset().top;
			if (contentBottom >= addAddressBtnTop) {
				this.setState({hasShadow: true});
			} else {
				this.setState({hasShadow: false});
			}
		}

		if (_.isNull(this.props.savedPlaces.savedHome) && _.isNull(this.props.savedPlaces.savedWork)) {
			$(".saved-places-others").css("margin-top", 0);
		} else {
			$(".saved-places-others").css("margin-top", "");
		}

		return true;
	}

	addAddress = (e) => {
		this.props.dispatch(setNavigationBlackBox({from: "", goBack: "", action: "addAddress"}));
		this.props.dispatch(savedPlacesSetType(_.isNull(this.props.savedPlaces.savedHome) ? "Home" :
			_.isNull(this.props.savedPlaces.savedWork) ? "Work" : "Custom"));
		this.props.dispatch(
			setEventForOpenMapOnSavedPlaces({ isEdited: false, productId: !_.isNull(this.props.product) || !_.isEmpty(this.props.product) ? this.props.product.id : null }),
		);
		this.props.dispatch(push("/saved-places/search"));
	};

	onClickSpecific = (e, place, type) => {
		if (!place) {
			this.props.dispatch(savedPlacesSetType(type));
			this.props.dispatch(push("/saved-places/search"));
		} else {
			this.props.dispatch(checkSelectedPlaceExist(place, this.props.pageName));
		}
	};

	onSelectPlace = (e, place, index) => {
		this.props.dispatch(checkSelectedPlaceExist(place, this.props.pageName));
	};

	render() {
		return (
			<div className="saved-places">
				<TitleBar
					title="Saved Places"
					leftIcon={backButton}
					handleClickLeftIcon={(e) => this.props.dispatch(closeLocationSearchBottomSheet())}
					rightIcon={this.props.noMap ? null : mapIcon}
					handleClickRightIcon={this.props.handleGoToMap}
				/>
				<div className={classNames({"saved-places-spacer": this.props.savedPlaces.all?.extra?.length || !_.isNull(this.props.savedPlaces.savedHome) ||
						!_.isNull(this.props.savedPlaces.savedWork)})}></div>
				<div className={classNames("saved-places-container", {"saved-places-no-content":
						!this.props.savedPlaces.all?.extra?.length && _.isNull(this.props.savedPlaces.savedHome) &&
						_.isNull(this.props.savedPlaces.savedWork && this.props.savedPlaces.fetchSavedPlacesLoading)
				})}>
					{this.props.savedPlaces.fetchSavedPlacesLoading ? (
						<div className="skeleton-saved-places">
							<SingleListSkeleton
								devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40}
							/>
						</div>
					) : !_.isNull(this.props.savedPlaces.all) && !this.props.common.loading ? (
						<SavedPlacesFavorite onClick={this.onClickSpecific} />
					) : null}

					{this.props.savedPlaces.fetchSavedPlacesLoading ? (
						<div className="skeleton-saved-places">
							<SingleListSkeleton
								devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40}
							/>
						</div>
					) : !_.isNull(this.props.savedPlaces.all) && !this.props.common.loading ? (
						<SavedPlacesOthers data={this.props.savedPlaces.all.extra} onClick={this.onSelectPlace} />
					) : null}

					{this.props.savedPlaces.fetchSavedPlacesLoading ? (
						<div className="skeleton-saved-places">
							<SingleListSkeleton
								devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40}
							/>
						</div>
					) : !this.props.savedPlaces.all?.extra?.length && _.isNull(this.props.savedPlaces.savedHome) &&
					_.isNull(this.props.savedPlaces.savedWork) ? (
						<div className="no-address-text">
							<h6>Tell us where!</h6>
							<p>Save time by saving your address; or just pick your current location.</p>
						</div>
					) : null }

					<div className={classNames("add-new-address", { "add-shadow": this.state.hasShadow })}>
						<CustomButton onClick={this.addAddress} title="Add New Address" type="button"
									  classList="" />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.search,
	common: state.common,
	product: state.booking.product,
	map: state.map,
	savedPlaces: state.savedPlaces,
});

export default connect(mapStateToProps)(SavedPlacesBottomSheet);
