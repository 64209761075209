import jwt from 'jwt-decode';
import _ from 'lodash';

import { 
    localStorageIsTwa, 
    // localStorageGetUsername, 
    localStorageGetToken,
    // localStorageGetLocation,
    localStorageGetIosApp, 
    localStorageGetUserId   
} from '../functions/local-storage';
import { VERSION_CWA_NUMBER } from "../constants/index";

export const APP_LAUNCHED_CWA = "App Launched";

export const APP_HERE_2_HELP_TAPPED_CWA = "Here2Help Tapped"; //DONE
export const APP_PRODUCT_VIEWED_CWA = "Product Viewed"; //DONE
export const APP_CHECKOUT_STARTED_CWA = "Checkout Started"; //DONE

//First Interaction
export const APPLICATION_LAUNCHED_CWA = "CA_appOpened";
export const ABOUT_PAGE_CWA = "About Page";
export const MY_JOBS_PAGE_CWA = "MyJobs Page";
export const ACCOUNT_PAGE_CWA = "Account Page";
export const NEWS_LIST_PAGE = "News List Page";
export const HOME_PAGE_CWA = "CA_homePageLoaded";
export const SEARCH_PAGE_CWA = "Search Page";
export const LOCATION_SHEET_OPENED_CWA = "Location Sheet Opened";
export const INITIAL_LOCATION_PICKED_CWA = "CA_initialLocationAdded";
export const HOME_DRAWER_OPENED_CWA = "Home Drawer Opened";
export const LAUNCH_FROM_NOTIFICATION_CWA = "Launch from Notification";
export const SCROLLED_THROUGH_SERVICES_CWA = "Scrolled Through Services";

//Welcomepage
export const WELCOME_PAGE_LOADED = "CA_welcomePageLoaded";
export const TRIED_GIVE_LOCATION__ACCESS = "CA_triedToGiveLocationAccess";

//Account Page
export const EDIT_PROFILE_PAGE_CWA = "Edit Profile Page";
export const PAYMENT_METHODS_PAGE_CWA = "Payment Methods Page";
export const SAVED_PLACES_PAGE_CWA = "Saved Places Page";
export const INVITE_FRIENDS_PAGE_CWA = "Invite Friends Page";
export const LOGOUT_CWA = "Logout";

//SignIn/SigUp flow
export const LOGIN_SHEET_OPENED_CWA = "Login Sheet Opened";
export const PHONE_NUMBER_SENT_CWA = "Phone Number Sent";
export const VERIFICATION_CODE_SENT_CWA = "Verification Code Sent";
export const RESEND_CODE_REQUESTED_CWA = "Resend Code Requested";
export const USER_INFO_SENT_CWA = "User Info Sent";
export const LOGIN_SHEET_CLOSED_CWA = "Login Sheet Closed";
export const USER_LOGGED_IN_CWA = "User Logged In";

//Booking flow
export const SERVICE_SELECTED_CWA = "CA_serviceSelected";
export const BOOKING_LOCATIONS_PAGE_CWA = "Booking Locations Page";
export const BOOKING_LOCATIONS_DID_SET_CWA = "Booking Locations Did Set";
export const BOOKING_DETAIL_DID_ENTER_CWA = "Booking Detail Did Enter";
export const BOOKING_DETAIL_PAGE_CWA = "Booking Detail Page";
export const REVIEW_PAGE_CWA = "CA_reviewPageLoaded";
export const REVIEW_EDIT_LOCATION_CWA = "Review Edit Location";
export const REVIEW_EDIT_NOTE_CWA = "Review Edit Note";
export const DISCOUNT_SHEET_OPENED_CWA = "Discount Sheet Opened";
export const TOPUP_SHEET_OPENED_CWA = "Top Up Sheet Opened";
export const BOOKING_DID_CANCEL_CWA = "Booking Did Cancel";
export const TRIED_TO_BOOK_CWA = "Tried to Book";
export const SERVICE_SCHEDULED_CWA = "Service Scheduled";
export const SERVICE_QUEUED_CWA = "Service Queued";
export const SERVICE_MATCHED_CWA = "Service Matched";
export const INSTANT_BOOKING_FAILED = "Instant Booking Failed";

//Saved places
export const SAVED_PLACES_OPENED = "LC_CA_savedPlacesOpened";
export const SAVED_PLACE_DELETED = "LC_CA_savedPlaceDeleted";
export const ADD_SAVED_PLACED_OPENED = "LC_CA_addSavedPlaceOpened";
export const LOCATION_FROM_MAP_OPENED = "LC_CA_locationFromMapOpened";
export const LOCATION_CONFIRMED = "LC_CA_locationConfirmed";
export const LOCATION_FROM_MAP_CONFIRMED = "LC_CA_locationFromMapConfirmed";
export const SAVED_PLACE_ADDED = "LC_CA_savedPlaceAdded";

//In app rating
export const AB_HAD_BOTH_FEEDBACKS = "AB_hadBothFeedbacks";

/**
 * 
 * @param {*} event 
 */
const isTwaOrCwa = (event) => {
    if (localStorageIsTwa()) {
        event = event.replace('CWA', 'TWA');
    }
    return event;
}

const getUserId = () => {
    try {
        const token = localStorageGetToken();
        if(!token) {
            return {
				fullName: "Guest",
				platform: "CWA",
				environment: process.env.REACT_APP_SEGMENT_ENV,
			};
        }
        const userDataEncoded = token.split(' ')[1];

        const { partyId } = jwt(userDataEncoded);
        return {
			userId: !_.isNull(partyId) ? partyId : localStorageGetUserId(),
			platform: "CWA",
			environment: process.env.REACT_APP_SEGMENT_ENV,
		};
    } catch(ex) {
        console.error(ex);
        return {};
    }
}

const getUserOS = () => {
    const os = localStorageGetIosApp() ? "iOS" : "Android"; 
    return os;
}
/**
 * Log Event for Segment
 * @param {*} event 
 */
export const logEvent = (event, eventData) => {
    try {
        if (window.analytics) {
            const { track } = window.analytics;
            const applicationId = "CLIENT_APP";
            const tokenDetails = getUserId();
            const OS = getUserOS();
            const eventBody = eventData ? 
                            { 
                                applicationId, 
                                applicationVersion: VERSION_CWA_NUMBER, 
                                OS,
                                ...tokenDetails, 
                                ...eventData,
                            } : { 
                                applicationId, 
                                applicationVersion: VERSION_CWA_NUMBER, 
                                OS,
                                ...tokenDetails,
                            }; 
            
            track(isTwaOrCwa(event), eventBody);
        }
    } catch(e) {
        console.error(e);
    }
}

export const identifyClientInSegment = (client) => {
    try {
        if(window.analytics) {
            const { identify } = window.analytics;
            const { givenName, lastName, emailAddress, mobileNumber, livingCity } = client;
            const identifyData = {
                firstName: givenName,
                lastName: lastName,
                email: emailAddress,
                phone: mobileNumber.fullNumber,
                address: {}
            };
            if(livingCity && livingCity.displayValue) {
                identifyData.address = { city: livingCity.displayValue };
            };

            window.partyId = client.id;
            identify(client.id , identifyData);
        }
    } catch(e) {
        console.error(e)
    }
}